import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/principal_desk',
    name: 'prncipalDesk',
    component: () => import('../views/PrincipalDesk.vue')
  },
  {
    path: '/past_principals',
    name: 'pastPrincipal',
    component: () => import('../views/PastPrincipal.vue')
  },
  {
    path: '/cod',
    name: 'codeOfConduct',
    component: () => import('../views/CodeOfConduct.vue')
  },
  {
    path: '/organogram',
    name: 'Organogram',
    component: () => import('../views/Organogram.vue')
  },
  {
    path: '/events',
    name: 'EventGallery',
    component: () => import('../views/EventGallery.vue')
  },
  {
    path: '/naac',
    name: 'NaacView',
    component: () => import('../views/Naac.vue')
  },
  {
    path: '/event/:id',
    name: 'EventDetails',
    component: () => import('../views/EventDetails.vue'),
    props: true
  },
  {
    path: '/departments',
    name: 'Department',
    component: () => import('../views/DepartmentTable.vue')
  },
  {
    path: '/elc',
    name: 'ELCView',
    component: () => import('../views/ELCView.vue')
  },
  {
    path: '/faculties/:id',
    name: 'Faculties',
    component: () => import('../views/Faculties.vue'),
    props: true
  },
  {
    path: '/subjects/:id',
    name: 'Subject',
    component: () => import('../views/Subject.vue'),
    props: true
  },
  {
    path: '/rti',
    name: 'RTI',
    component: () => import('../views/RTI.vue'),
    props: true
  },
  {
    path: '/comittees',
    name: 'Comittee',
    component: () => import('../views/Departments.vue')
  },
  {
    path: '/admissions',
    name: 'Admission',
    component: () => import('../views/Admissions.vue')
  },
  {
    path: '/circular',
    name: 'Circular',
    component: () => import('../views/Circulars.vue')
  },
  {
    path: '/non_teaching_staff',
    name: 'NonTeachingStaff',
    component: () => import('../views/NonTeachingStaff.vue')
  },
  {
    path: '/teaching_staff',
    name: 'TeachingStaff',
    component: () => import('../views/TeachingStaff.vue')
  },
  {
    path: '/ouc',
    name: 'OUC',
    component: () => import('../views/Ouc.vue')
  },
  {
    path: '/scholarships',
    name: 'Scholarship',
    component: () => import('../views/Scholarship.vue')
  },
  {
    path: '/nss',
    name: 'NSS',
    component: () => import('../views/NSS.vue')
  },
  {
    path: '/contact',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/iqac/:name',
    name: 'IQAC',
    component: () => import('../views/IQACType.vue'),
    props: true
  },
  {
    path: '/iqac_all',
    name: 'IQACAll',
    component: () => import('../views/IQAC.vue'),
  },
  {
    path: '/courses_offered',
    name: 'CoursesOffered',
    component: () => import('../views/CoursesOffered.vue'),
  },
  {
    path: '/practical_fee',
    name: 'PracticalFee',
    component: () => import('../views/PracticalFee.vue'),
  },
  {
    path: '/practical_reciept/:txnId',
    name: 'PracticalFeeReciept',
    props: true,
    component: () => import('../views/PracticalFeeReceipt.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
